import React, {Component} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt, faBars} from "@fortawesome/free-solid-svg-icons";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { withTranslation } from 'react-i18next';

class CustomChoiceSection extends Component {

    constructor(props) {
        super(props);

        this.SINGLE_CHOICE_TYPE = window.questiaSpecificWebsiteConfig.CARD_TYPES.CARD_SINGLE_CHOICE_CLASSIC;
        this.MULTIPLE_CHOICE_TYPE = window.questiaSpecificWebsiteConfig.CARD_TYPES.CARD_MULTIPLE_CHOICE_CLASSIC;
    }

    customChoices = () => {
        return this.props.choices.map((choice, index) => {
            return (
                <Draggable class="draggable" draggableId={index + "_draggables"} index={index}
                           key={"draggable_choice_item_key_" + index}>
                    {(provided) => (
                        <div ref={provided.innerRef}
                             {...provided.draggableProps}
                             key={index + "_key"}>
                            <div className="form-group questia-question__form">
                                <label htmlFor={"option" + index}>{choice.label}</label>
                                <div className="d-flex align-items-center">
                                <span className="handle instant" {...provided.dragHandleProps}>
                                    <FontAwesomeIcon className="icon-font-awesome mr-2" icon={faBars}/>
                                </span>
                                    <input type="text"
                                           maxLength="150"
                                           placeholder={this.props.t('propose.content.choice_text_max_characters',"max 150...")}
                                           className="form-control"
                                           id={"option" + index}
                                           value={choice.value}
                                           onChange={(e) =>
                                               this.props.handleChoiceTextChange(e, index)}
                                           required
                                    />
                                    {this.props.choices.length > 2 &&
                                        <a className="btn btn-danger ml-2 deleteOption--js"
                                           href="javascript:void(0)"
                                           onClick={(e) => this.props.onDeleteClick(e, index)}>
                                            <FontAwesomeIcon className="icon-font-awesome" icon={faTrashAlt}/>
                                        </a>
                                    }
                                </div>
                            </div>
                        </div>
                    )}
                </Draggable>
            );
        });
    };

    render () {
        return (
            <React.Fragment>
                <div className="questia-question__item--inner px-sm-4">
                    <div className="form-group questia-question__form">
                        <div className="d-flex align-items-center">
                            <legend className="">{this.props.t('propose.content.response_type_text',"Response type")}</legend>
                            <div className="form-check form-check-inline m-0">
                                <input className="form-check-input"
                                       type="radio"
                                       name="option"
                                       id="radio"
                                       value={this.SINGLE_CHOICE_TYPE}
                                       checked={this.props.questionType === this.SINGLE_CHOICE_TYPE}
                                       onChange={this.props.handleQuestionTypeRadio}
                                />
                                <label className="form-check-label" htmlFor="radio">
                                    {this.props.t('propose.content.single_choice_text',"Single choice")}
                                </label>
                            </div>
                            <div className="form-check form-check-inline m-0">
                                <input className="form-check-input"
                                       type="radio"
                                       name="option"
                                       id="checkbox"
                                       value={this.MULTIPLE_CHOICE_TYPE}
                                       checked={this.props.questionType === this.MULTIPLE_CHOICE_TYPE}
                                       onChange={this.props.handleQuestionTypeRadio}
                                />
                                <label className="form-check-label" htmlFor="checkbox">
                                    {this.props.t('propose.content.multiple_choice_text',"Multiple choice")}
                                </label>
                            </div>

                        </div>
                    </div>
                    <DragDropContext onDragEnd={this.props.onDragEnd}>
                        <Droppable droppableId="droppable_choice" class="droppable_choice">
                            {(provided, snapshot) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {this.customChoices()}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>

                </div>

                {this.props.choicesError &&
                    <span className="invalid-feedback" style={{display: "block"}}>
                        {this.props.t('propose.content.question_choice_number_error',"You have reached the maximum number of choices")}
                    </span>
                }
                <div className="col-auto mt-4 d-flex justify-content-center">
                    <a href="javascript:void(0)" id="addNewOption--js" className="href--blue"
                        onClick={this.props.onAddOptionClick}>
                        {this.props.t('propose.content.new_option_button',"Add a new option")}
                    </a>
                </div>
            </React.Fragment>
        )
    }
}

export default withTranslation()(CustomChoiceSection);