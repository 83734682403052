import React from "react";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";

export default class Pagination extends React.Component {
    constructor(props) {
        super(props);

        this.changePage = this.changePage.bind(this);

        this.state = {
            visiblePages: this.getVisiblePages(null, props.pages)
        };
    }

    static propTypes = {
        pages: PropTypes.number,
        page: PropTypes.number,
        onPageChange: PropTypes.func,
    };

    componentWillReceiveProps(nextProps) {
        if (this.props.pages !== nextProps.pages) {
            this.setState({
                visiblePages: this.getVisiblePages(null, nextProps.pages)
            });
        }

        this.changePage(nextProps.page + 1);
    }

    filterPages = (visiblePages, totalPages) => {
        return visiblePages.filter(page => page <= totalPages);
    };

    getVisiblePages = (page, total) => {
        if (total <= 5 || page < 5)
            return this.filterPages([1, 2, 3, 4, 5], total);
        if (page + 3 > total)
            return this.filterPages([page - 2, page - 1, page, page + 1, page + 2], total);
        return [page - 2, page - 1, page, page + 1, page + 2];
    };

    changePage(page) {
        const activePage = this.props.page + 1;

        if (page === activePage) {
            return;
        }

        const visiblePages = this.getVisiblePages(page, this.props.pages);

        this.setState({
            visiblePages: this.filterPages(visiblePages, this.props.pages)
        });

        this.props.onPageChange(page - 1);
    }

    render() {
        const { visiblePages } = this.state;
        const activePage = this.props.page + 1;

        return (
            <div className="navigation__wrapper">
                <tr className="table__row table__row--footer">
                    <td className="cell__navigation" colSpan="5">
                        <div className="table__navigation">
                            <ul className="table__navigation__pages">
                                {visiblePages.map((page, index, array) => {
                                    return (
                                        <li className={activePage === page ? "active" : ""}
                                            onClick={this.changePage.bind(null, page)}>
                                            <a> {array[index - 1] + 2 < page ? `...${page}` : page} </a>
                                        </li>
                                    );
                                })}
                            </ul>
                            {
                                this.props.pages > 1 &&
                                (
                                    <React.Fragment>
                                        <a className="table__navigation--left"
                                           onClick={() => {
                                               if (activePage === 1) return;
                                               this.changePage(activePage - 1);
                                           }}>
                                            <FontAwesomeIcon icon={faChevronLeft}/>
                                        </a>
                                        <a className="table__navigation--right"
                                           onClick={() => {
                                               if (activePage === this.props.pages) return;
                                               this.changePage(activePage + 1);
                                           }}>
                                            <FontAwesomeIcon icon={faChevronRight}/>
                                        </a>
                                    </React.Fragment>
                                )
                            }
                        </div>
                    </td>
                </tr>
            </div>

        );
    }
}
