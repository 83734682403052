import React, {Component} from 'react';
import {withTranslation} from "react-i18next";

class WelcomeWidget extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <a target="_blank" href={window.questiaSpecificWebsiteConfig.FACEBOOK_PAGE_URL} className="box bg--white text-center">
                        <span
                            className="box__title color--black pv-sm">{this.props.t('sidebarRight.widget_facebook.text1_1',"Follow us on")}&nbsp;<strong>{this.props.t('sidebarRight.widget_facebook.text1_2',"<K2T> questia.this.props.t('sidebarRight.widget_facebook.text1_2 </K2T>")}</strong>{this.props.t('sidebarRight.widget_facebook.text1_3',"!")}</span>
                <img className="box__image" src={"specific/images/fb_box.png"} alt=""/>
                <img className="box__image mv-sm" src={"specific/images/man_floor.png"} alt=""/>
            </a>
        );
    }
}

export default withTranslation()(WelcomeWidget);