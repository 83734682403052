import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";

class ProposeQuestionWidget extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Link to="/app/propose" class="box bg--white box--propose text-center" style={{backgroundImage: "url(" + "specific/images/questia_gray_big.png)"}}>
                <span class="box__text pt-sm pb-xs">{this.props.t('sidebarRight.widget_propose_question.text1_1',"Do you have a question that could be part of the question gallery on")}&nbsp;<b>{this.props.t('sidebarRight.widget_propose_question.text1_2',"Questia")}</b>{this.props.t('sidebarRight.widget_propose_question.text1_3',"?")}</span>
                <span class="box__text pb-md"><strong>{this.props.t('sidebarRight.widget_propose_question.text2',"Send us your idea!")}</strong></span>
                <img class="box__image pb-sm" src={"specific/images/laptop-girl-small.png"} alt=""/>
            </Link>
        );
    }
}

export default withTranslation()(ProposeQuestionWidget);