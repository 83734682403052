import React, {Component} from 'react';
import withGlobalContext from "../../hocs/withGlobalContext";
import PropTypes, {instanceOf} from "prop-types";
import {withCookies, Cookies} from 'react-cookie';
import {withRouter} from 'react-router-dom';
import LoadingIndicator from "../../meta/LoadingIndicator";
import AuthenticationUtils from "../../../components/utils/AuthenticationUtils";

class SecurityWrapperEmbed extends Component {
    static propTypes = {
        globalContext: PropTypes.object.isRequired,
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);

        let globals = this.props.cookies.get('globals' + window.questiaSpecificWebsiteConfig.COOKIE_LOCALE);

        this.state = {
            globals: globals,
            isGuestReady: false
        }
    }

    componentDidMount() {
        const {cookies, history} = this.props;
        const {globals} = this.state;

        if (!globals) {
            this.props.globalContext.handleResetContextGlobalData();
            AuthenticationUtils.handleGuestUserAuthentication(cookies, history,
                () => {
                    this.setState({isGuestReady: true});
                },
                (error) => {
                    console.log(error)
                }
            );
        } else {
            if (!this.props.globalContext.isUserDataLoaded) {
                this.props.globalContext.handleInitContextGlobalData(false);
            }
        }
    }

    render() {
        var {globalContext} = this.props;

        if (globalContext.isUserDataLoaded || this.state.isGuestReady) {
            return (
                <React.Fragment>
                    {this.props.children}
                </React.Fragment>
            )
        } else {
            return (
                <LoadingIndicator/>
            );
        }
    }
}

export default withCookies(withRouter(withGlobalContext(SecurityWrapperEmbed)));