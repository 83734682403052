import React,{Component} from 'react';
import withGlobalContext from "../../hocs/withGlobalContext";
import withAuthenticationContext from "../../hocs/withAuthenticationContext";
import {withToastManager} from "react-toast-notifications";
import withHeader from "../../hocs/withHeader";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import queryString from "query-string";
import TermsAndPrivacyModal from "../User/TermsAndPrivacyModal";
import LoadingIndicator from "../../meta/LoadingIndicator";

class AituAuthComp extends Component{

   constructor(props){
       super(props);

       const queryParamValues = queryString.parse(this.props.location.search);
       var userIsErgEmployee = (typeof queryParamValues.utm_source !== "undefined" && queryParamValues.utm_source === 'erg');

       if(!window.questiaSpecificWebsiteConfig.AITU_APP_ENABLED || typeof queryParamValues.code === 'undefined'){
           this.props.history.push("/login");
       }

       this.state = {
           code: queryParamValues.code,
           loading: true,
           showAgreementModal: false,
           userIsErgEmployee: userIsErgEmployee
       }
   }

    goBackAfterTimeout = () => {
        window.setTimeout(() => {
            this.props.history.goBack();
        }, 5000);
    };


    authenticateUser = (userData) => {
       const {functions} = this.props.authenticationContext;

       this.setState({
           userPhone: userData.phone + "@phone.number",
           userFirstName : userData.first_name,
           userLastName : userData.last_name,
           accessToken: userData.accessToken});

       functions.defaultLogin(null,null,null,null,null,userData.accessToken,
           (success)=>{
               if(success.data.user_agrees_contact){
                   functions.saveCredentials(success.data.token, success.data.user_id,
                       success.data.level, success.data.forecast_segment,
                       success.data.panel_segment, success.data.user_agrees_contact);
                   this.props.authenticationContext.functions.redirectAfterLogin(this.props.globalContext.globals.redirectTo,this.props.history);
               }else{
                   this.setState({
                       alreadyRegistered: true,
                       userId: success.data.user_id
                   }, () => {
                       this.toggleAgreementModal(true);
                   });
               }
           },
           (error)=>{
               if (error.data.code && window.questiaSpecificWebsiteConfig.CODE_USER_NOT_ACTIVE === error.data.code) {
                   functions.showLoginErrorMessageBasedOnErrorCode(error.data.code);
                   this.goBackAfterTimeout();
               } else if(error.data.code && window.questiaSpecificWebsiteConfig.CODE_USER_NON_EXISTENT === error.data.code){
                   this.setState({
                       alreadyRegistered: false
                   }, () => {
                       this.toggleAgreementModal(true);
                   });
               } else {
                   functions.showLoginErrorMessageBasedOnErrorCode(false);
                   this.goBackAfterTimeout();
               }
           })
   };

   componentDidMount() {
       const {functions} = this.props.authenticationContext;
       functions.verifyAituUserAndGetUserData('aitu',this.state.code,
             (success) => {
                  this.authenticateUser(success.data);
             }, (error)=>{
                  this.props.history.push("/login?aitu_failed_auth=true");
             });
   }

    toggleAgreementModal = (showModal) => {
        this.setState({showAgreementModal: showModal});
    };

    agreementConfirmFunction = () => {
        const {functions} = this.props.authenticationContext;
        var {alreadyRegistered} = this.state;
        var userData = {
            userId: this.state.userId,
            userAgreement: true,
            recommendedBy: this.props.globalContext.recommendedBy,
            userLanguageString: this.props.globalContext.lang,
            userFirstName : this.state.userFirstName,
            userLastName : this.state.userLastName,
            accessToken : this.state.accessToken,
            userErgEmployee: this.state.userIsErgEmployee
        };

        functions.selectAndExecuteAituRegistrationType(this.state.userPhone, alreadyRegistered, userData, this.props.globalContext.globals.redirectTo,this.props.history,
            (success) => {
                functions.saveCredentials(success.data.token, success.data.user_id,
                    success.data.level, success.data.forecast_segment,
                    success.data.panel_segment, success.data.user_agrees_contact);
                this.props.globalContext.functions.clearRecommendedByCookie();
                this.props.authenticationContext.functions.redirectAfterLogin(this.props.globalContext.globals.redirectTo,this.props.history);
            },
            (error) => {
                this.props.history.push("/login?aitu_failed_auth=true");
            }
        );

    };

    agreementDeclineFunction = () => {
        this.props.history.push("/login");
    };

   render() {
       return( <section className="jumbotron">
           <div className="container">
               <div className="row">
                   <div className="col-lg-4 offset-lg-4">
                       <TermsAndPrivacyModal showModal={this.state.showAgreementModal}
                                             toggleShowModal={this.toggleAgreementModal}
                                             agreementConfirmFunction={this.agreementConfirmFunction}
                                             agreementDeclineFunction={this.agreementDeclineFunction}
                       />
                       <div className="login-form login pv-xs">
                           <p className="login-form__title pv-xs">{this.props.t('aitu_page.content.title',"Connecting with Aitu...")}</p>
                           {
                               this.state.loading && <LoadingIndicator/>
                           }
                       </div>
                   </div>
               </div>
           </div>
       </section>)
   }


}

export default withGlobalContext(withAuthenticationContext(withToastManager(withHeader(withTranslation()(withRouter(AituAuthComp))))));