import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCopyright} from '@fortawesome/free-solid-svg-icons';
import UserInfo from "../User/UserInfo";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import withGlobalContext from "../../hocs/withGlobalContext";
import { withTranslation } from 'react-i18next';
import Sticky from 'react-stickynode';
import {faFacebookSquare, faInstagramSquare, faTwitterSquare} from "@fortawesome/free-brands-svg-icons";
import QuestiaUtils from "../../utils/QuestiaUtils";

class SidebarLeft extends React.Component {
    static propTypes = {
        globalContext: PropTypes.object.isRequired
    };

    constructor(props){
        super(props);
    }

    closeSideBarMobile = () => {
        var sidebar = document.getElementsByClassName("sidebar-left")[0];
        sidebar.className = "sidebar-left";
    };

    render() {
        var {globals} = this.props.globalContext;
        var {userInfo} = this.props.globalContext;

        const hideBlockedHTML = window.questiaSpecificWebsiteConfig.BLOCKING_PROFILE && !globals.hasUserCompletedProfile;

        const domain = window.location.hostname.startsWith("www") ? window.location.hostname.slice(3) : window.location.hostname;
        const predictorSiteUrl = "https://predictor" + (domain.startsWith(".") ? "" : ".") + domain;

        return (
            <Sticky className="left-col" innerClass="left-col-container" top={75}>
                <div className="profile text-center">
                    <UserInfo globals={globals} userInfo={userInfo}/>
                    <div className="cta__wrapp text-center py-4">
                        <Link to="/app/spendpoints"
                            className="btn btn--questia btn--outline__yellow py-2 fw-500">{this.props.t('sidebarLeft.content.convert_points_button',"Convert your points")}</Link>
                    </div>

                    {!hideBlockedHTML &&
                    <ul className="profile__menu text-left px-sm-4">
                        <li className="profile__menu__item"><Link  id="surveyBottonId" to="/app/surveys">{this.props.t('sidebarLeft.content.link_text_surveys',"Polls")}</Link></li>
                        {
                            window.questiaSpecificWebsiteConfig.PROPOSE_QUESTION_ACTIVE &&
                            <li className="profile__menu__item"><Link to="/app/propose">{this.props.t('sidebarLeft.content.link_text_propose_question',"Submit a question")}</Link></li>
                        }
                        <li className="profile__menu__item"><Link to="/app/invite">{this.props.t('sidebarLeft.content.link_text_invite_friend',"Invite friends")}</Link></li>
                        {window.questiaSpecificWebsiteConfig.PREDICTOR_ACTIVE && QuestiaUtils.userHasPredictorRights(this.props.globalContext.globals.level) &&
                            <li className="profile__menu__item"><a href={predictorSiteUrl}>{this.props.t('sidebarLeft.content.link_text_predictor',"Predictor")}</a></li>
                        }
                    </ul>
                    }
                </div>

                <div className="footer__small mt-4 px-sm-4">
                    <ul className="footer__list pl-sm-4">
                        <li className="footer__list__item"><Link to="/app/contact">{this.props.t('footer.content.link_text_contact',"Contact")}</Link></li>
                        <li className="footer__list__item"><Link to="/app/privacy">{this.props.t('footer.content.link_text_privacy',"Privacy policy")}</Link></li>
                        <li className="footer__list__item"><Link to="/app/terms">{this.props.t('footer.content.link_text_terms',"Terms and conditions")}</Link></li>
                        <li className="footer__list__item"><Link to="/app/faq">{this.props.t('footer.content.link_text_faq',"FAQ")}</Link></li>
                    </ul>
                    <ul className="footer__list pl-sm-4 my-3">
                        <li className="footer__list__item">
                            {window.questiaSpecificWebsiteConfig.FACEBOOK_PAGE_URL &&
                            <a target="_blank" href={window.questiaSpecificWebsiteConfig.FACEBOOK_PAGE_URL}
                               className="footer-social"><i className="fa fa-facebook-square"><FontAwesomeIcon
                                icon={faFacebookSquare}></FontAwesomeIcon></i></a>
                            }
                            {window.questiaSpecificWebsiteConfig.INSTAGRAM_PAGE_URL &&
                            <a target="_blank" href={window.questiaSpecificWebsiteConfig.INSTAGRAM_PAGE_URL}
                               className="footer-social"><i className="fa fa-instagram"><FontAwesomeIcon
                                icon={faInstagramSquare}></FontAwesomeIcon></i></a>
                            }
                            {window.questiaSpecificWebsiteConfig.TWITTER_PAGE_URL &&
                            <a target="_blank" href={window.questiaSpecificWebsiteConfig.TWITTER_PAGE_URL}
                               className="footer-social"><i className="fa fa-twitter-square"><FontAwesomeIcon
                                icon={faTwitterSquare}></FontAwesomeIcon></i></a>
                            }
                        </li>
                    </ul>
                    <span className="footer__legal pl-sm-4 color--blue"><FontAwesomeIcon icon={faCopyright}></FontAwesomeIcon><span
                        className="footer__legal__year"></span> {this.props.t('quests.index.title',"Questia")} </span>
                </div>
            </Sticky>
        );
    }
}
export default withGlobalContext(withTranslation()(SidebarLeft));