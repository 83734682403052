import React from 'react';
import {AuthenticationContext} from "../services/Authentication/AuthenticationContext";

function withAuthenticationContext(Component) {
    return function WrapperComponent(props) {
        return (
            <AuthenticationContext.Consumer>
                {authenticationContext => <Component {...props} authenticationContext={authenticationContext} />}
            </AuthenticationContext.Consumer>
        );
    };
}

export default withAuthenticationContext;