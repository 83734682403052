import React, {Component} from 'react';
import Header from "../layout/Header/Header";

function withHeader(WrappedPageComponent) {
    return class extends Component {
        constructor(props) {
            super(props)
        }

        render() {
            return (
                <React.Fragment>
                    <Header/>
                    <WrappedPageComponent {...this.props}/>
                </React.Fragment>
            );
        }
    }
}

export default withHeader;