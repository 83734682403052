import React, {Component} from 'react';
import PropTypes from "prop-types";
import Select from "react-select";
import ReactTable from 'react-table';
import "react-table/react-table.css";
import ChoiceRenderer from "../../layout/ChoiceRenderer/ChoiceRenderer";
import CustomChoiceSection from "../../layout/ChoiceRenderer/CustomChoiceSection";
import LoadingIndicator from "../../meta/LoadingIndicator";
import withGlobalContext from "../../hocs/withGlobalContext";
import ErrorBoundary from "../../meta/ErrorBoundary";
import { withToastManager } from 'react-toast-notifications';
import moment from 'moment';
import ProposeQuestionColumnFilter from "./ProposeQuestionColumnFilter";
import ProposeQuestionPagination from "./ProposeQuestionPagination";
import { withTranslation } from 'react-i18next';
import SidebarRightMobile from '../Sidebars/SidebarRightMobile'

const customDropdownStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            color: isFocused ? '#ffffff' : '#1e1e22',
            padding: '5px 15px',
            fontSize: '16px',
            border: '1px solid transparent',
            borderColor: 'transparent transparent rgba(0, 0, 0, 0.1) transparent',
            cursor: 'pointer',
            userSelect: 'none',
            backgroundColor: isFocused ? '#b9d15e' : (isSelected ? 'rgba(0, 0, 0, 0.1)' : null)
        }
    },
    control: (styles, state) => {
        return {
            ...styles,
            backgroundColor: '#ffffff',
            color: '#909090',
            padding: '5px 15px',
            fontSize: '16px',
            border: '1px solid #e2e2e2',
            cursor: 'pointer',
            userSelect: 'none',
            '-webkit-border-radius': '10px',
            '-moz-border-radius': '10px',
            borderRadius: '10px',
            '-webkit-transition': 'all 0.3s ease-in-out',
            '-moz-transition': 'all 0.3s ease-in-out',
            '-o-transition': 'all 0.3s ease-in-out',
            transition: 'all 0.3s ease-in-out',
            boxShadow: state.isFocused ? 0 : 0,
            '&:hover': {
                outline: 'none'
            }
        }
    }
};

const baseCard = {
    choices: [],
    dateEnd: null,
    dateStart: null,
    haveMedia: "0",
    id: null,
    text: "",
    topicName: "user_generated",
    type: ""
};

class ProposeQuestion extends Component {
    static propTypes = {
        globalContext: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);

        this.MAX_CHOICES = 8;
        this.defaultCustomChoices = Array.from({length: 3}, (_, i) => i + 1).map(index => {
            return {
                value: "",
                label: this.props.t('propose.content.choice_label',"Option ") + index
            }
        });

        this.state = {
            card: JSON.parse(JSON.stringify(baseCard)),
            isDataFetched: false,
            defaultChoiceTypes: [],
            defaultChoices: {},
            selectedChoiceType: null,
            choicesError: false,
            proposedQuestions: []
        };

        this.handleChangeQuestionText = this.handleChangeQuestionText.bind(this);
        this.handleChangeChoiceType = this.handleChangeChoiceType.bind(this);
    };

    getQuestionTypeForSelectedChoiceType = (selectedChoiceType) => {
        if(selectedChoiceType.value === null)
            return window.questiaSpecificWebsiteConfig.CARD_TYPES.CARD_SINGLE_CHOICE_CLASSIC;
        const choices = this.state.defaultChoices[selectedChoiceType.value];
        return (choices.length === 5 ? window.questiaSpecificWebsiteConfig.CARD_TYPES.CARD_SINGLE_CHOICE_CLASSIC: window.questiaSpecificWebsiteConfig.CARD_TYPES.CARD_VERTICAL_BUTTONS);
    };

    handleChangeQuestionText = (evt) => {
        let {card} = this.state;
        card.text = evt.target.value;

        this.setState({card: card});
    };

    handleChangeChoiceType = (selectedChoiceType) => {
        const {toastManager} = this.props;

        const recommendedQuestionType = this.getQuestionTypeForSelectedChoiceType(selectedChoiceType);
        if (!recommendedQuestionType)
            toastManager.add(this.props.t('propose.messages.question_choice_type_not_supported',"The question scale is not supported!"), {
                appearance: 'warning',
                autoDismiss: window.questiaSpecificWebsiteConfig.NOTIFICATIONS_AUTO_DISMISS,
                autoDismissTimeout: window.questiaSpecificWebsiteConfig.NOTIFICATIONS_AUTO_DISMISS_TIMEOUT
            });

        let {card} = this.state;
        card.choices = selectedChoiceType.value !== null ? this.state.defaultChoices[selectedChoiceType.value]
            : this.defaultCustomChoices;
        card.type = recommendedQuestionType;

        this.setState({
            card: card,
            selectedChoiceType: selectedChoiceType
        });
    };

    fetchDefaultChoiceTypes = () => {
        let langId = this.props.globalContext.userInfo.language.lang_id;
        return this.props.globalContext.services.questionsAPI.getDefaultChoiceTypesByLanguageId(langId,
            (response) => {
                this.setState({
                    defaultChoiceTypes: this.buildDefaultChoiceTypes(response.data)
                })
            },
            (error) => {
                console.log(error);
            }
        );
    };

    fetchDefaultChoices = () => {
        let langId = this.props.globalContext.userInfo.language.lang_id;
        return this.props.globalContext.services.questionsAPI.getDefaultChoicesByLanguageId(langId,
            (response) => {
                this.setState({
                    defaultChoices: response.data
                })
            },
            (error) => {
                console.log(error);
            }
        );
    };

    fetchProposedQuestions = () => {
        return this.props.globalContext.services.questionsAPI.getProposedQuestions(
            (response) => {
                this.setState({
                    proposedQuestions: response.data,
                    isDataFetched: true
                });
            },
            (error) => {
                this.setState({
                    proposedQuestions: [],
                    isDataFetched: true
                });
            }
        );
    };

    getQuestionsAndChoices = () => {
        this.fetchDefaultChoiceTypes();
        this.fetchDefaultChoices();
        this.fetchProposedQuestions();
    }

    componentDidMount = () => {
        this.getQuestionsAndChoices();
    };

    componentDidUpdate(prevProps) {
        if (prevProps.globalContext.userInfo.language.lang_id !== this.props.globalContext.userInfo.language.lang_id) {
            this.setState({
                card: JSON.parse(JSON.stringify(baseCard)),
                selectedChoiceType: null
            }, () => {
                this.getQuestionsAndChoices();
            });
        }
    };

    buildDefaultChoiceTypes = (defaultChoiceTypesData) => {
        let defaultChoiceTypes = defaultChoiceTypesData.map(element => {
            return {value: element.choice_type_id, label: element.choice_type_translated};
        });
        const customChoice = {value: null, label: this.props.t('propose.content.custom_choices_text',"Custom choices")};
        return [customChoice].concat(defaultChoiceTypes);
    };

    buildProposedQuestionsTableColumns = () => {
        return [
            {
                id: 'col0',
                accessor: d => d.questionDetails,
                sortable: false,
                Cell: props => (
                    <React.Fragment>
                        <span className="cell__details cell__details--date">{props.value.datetime}</span>
                        <span className="cell__question"> <a href="javascript:void(0)" style={{color: '#1e1e22'}}
                                                             onClick={() => {window.open(window.location.origin+"/app/cardPage/"+props.value.id)}} >{props.value.text}</a></span>
                        <span className="cell__details cell__details--answer">{props.value.answersCount} {this.props.t('propose.content.answers',"Answers ")}</span>
                    </React.Fragment>
                ),
                Filter: props => <ProposeQuestionColumnFilter {...props} />,
                filterMethod: (filter, row) => {
                    const rowText = row[filter.id].text;
                    const filterValue = filter.value.filterValue;
                    if (!filterValue)
                        return true;
                    return rowText.includes(filterValue);
                }
            },
            {
                id: 'col1',
                accessor: 'questionStatus',
                sortable: false,
                filterable: false,
                Cell: props => (
                    <React.Fragment>
                        <span className={"cell__details cell__details--status status status--" + ( (props.value.isAccepted == 1) ? "approved": "pending")}>
                            {
                                (props.value.isAccepted === 1) ?
                                    this.props.t('propose.content.question_accepted',"Accepted"):
                                    (
                                        (props.value.isAccepted === 2) ?
                                            this.props.t('propose.content.question_rejected',"Rejected"):
                                            this.props.t('propose.content.question_pending',"Stand-by")
                                    )
                            }
                        </span>
                    </React.Fragment>
                )
            },
        ];
    };

    buildProposedQuestionsTable = () => {
        moment.locale(this.props.globalContext.userInfo.language.lang_moment_locale);
        const {proposedQuestions} = this.state;
        const proposedQuestionsTableColumns = this.buildProposedQuestionsTableColumns();
        const proposedQuestionsTableData = proposedQuestions.map(proposedQuestion => {
            let questionId = proposedQuestion.questionId;
            let questionText = proposedQuestion.questionText;
            let questionDatetime = moment(proposedQuestion.questionDatetime).format("DD MMMM YYYY");
            let answersCount = proposedQuestion.answersCount;
            let questionIsAccepted = proposedQuestion.questionIsAccepted;

            return(
                {
                    questionDetails: {
                        id: questionId,
                        text: questionText,
                        datetime: questionDatetime,
                        answersCount: answersCount
                    },
                    questionStatus: {
                        isAccepted: questionIsAccepted,
                        promoteLink: null,
                    }
                }
            );
        });

        return (
            <div className="proposed__questions__table">
                <ReactTable
                    data={proposedQuestionsTableData}
                    columns={proposedQuestionsTableColumns}
                    filterable={true}
                    noDataText={this.props.t('propose.content.no_questions_text',"No question submitted")}
                    PaginationComponent={ProposeQuestionPagination}
                    defaultPageSize = {5}
                />
            </div>
        );
    };

    onDeleteClick = (e, index) => {
        let { card } = this.state
        card.choices.splice(index, 1);
        this.setState({card: card, choicesError: false})
    }

    onAddOptionClick = (e) => {
        let { card } = this.state
        if(card.choices.length >= this.MAX_CHOICES){
            this.setState({choicesError: true})
            return;
        }
        let choiceNameArray = card.choices.map(choice => choice.label)
        let newChoiceName = "Option " +
            //array of the [1...n] form
            Array.from({length: this.MAX_CHOICES}, (_, i) => i + 1)
                .find(index => !choiceNameArray.includes("Option " + index))
        card.choices.push({label: newChoiceName, value: ""})
        this.setState({card: card, choicesError: false})
    }

    handleChoiceTextChange = (event, index) => {
        const newText = event.target.value;
        if (newText || newText === "") {
            let { card } = this.state
            card.choices[index].value = newText;
            this.setState({card: card, choicesError: false})
        }
    };

    onDragEnd = (e) => {
        const sourceIdx = e.source.index;
        const destinationIdx = e.destination.index;
        let { card } = this.state

        const removed = card.choices.splice(sourceIdx,1);
        card.choices.splice(destinationIdx, 0, removed[0]);
        this.setState({card: card})
    }

    handleQuestionTypeRadio = (e) => {
        let { card } = this.state
        card.type = e.target.value
        this.setState({card: card})
    }

    render() {
        const {isDataFetched, defaultChoiceTypes, card, selectedChoiceType} = this.state;

        if (!isDataFetched)
            return (
                <section className="main-col">
                    <LoadingIndicator/>
                </section>
            );

        let proposedQuestionsTable = this.buildProposedQuestionsTable();
        let choicesPreview = this.buildChoicesPreview(card, selectedChoiceType);

        return (
            <section className="main-col">
                <div className="form">
                    <div className="questia-question__item mb-4 py-3">

                        <div className="questia-question__item--header d-flex align-self-stretch align-items-center justify-content-between mb-3">
                            <div className="text-left d-flex justify-content-start">
                                <p className="fs-12 fw-500 fc-black d-flex">
                                    <svg className="mr-2" xmlns="http://www.w3.org/2000/svg" width="16.858" height="16.135"
                                         viewBox="0 0 16.858 16.135">
                                        <path id="Icon_awesome-star" data-name="Icon awesome-star"
                                              d="M8.966.56,6.908,4.732,2.3,5.4a1.009,1.009,0,0,0-.558,1.72l3.331,3.246L4.29,14.954a1.008,1.008,0,0,0,1.462,1.062L9.87,13.851l4.118,2.165a1.008,1.008,0,0,0,1.462-1.062l-.788-4.585,3.331-3.246a1.009,1.009,0,0,0-.558-1.72l-4.6-.671L10.775.56a1.009,1.009,0,0,0-1.809,0Z"
                                              transform="translate(-1.441 0.001)" fill="#cbcadb"/>
                                    </svg>
                                    <span className="title__text">{this.props.t('propose.content.user_generated_topic',"Submitted by the community")}</span>
                                </p>
                            </div>
                        </div>

                        <div className="questia-question__item--headline px-sm-4">
                            <div className="questia-question__item--headline__big">
                                    <div className="form-group questia-question__form">
                                        <label htmlFor="addQuestion">{this.props.t('propose.content.textarea_placeholder',"Add your question here")}</label>
                                        <textarea type="text"
                                            value={this.state.card.text}
                                            onChange={this.handleChangeQuestionText}
                                            className="form-control"
                                            id="addQuestion"
                                            placeholder={this.props.t('propose.content.textarea_max_characters',"max 500...")}
                                            style={{height: "94px", minHeight: "50px"}}
                                        />
                                    </div>
                            </div>
                        </div>
                        {
                            choicesPreview &&
                            (
                                <div className="questia-question__item--inner px-sm-4 pb-4">
                                    <ErrorBoundary boundaryName="a choice viewer">
                                        {choicesPreview}
                                    </ErrorBoundary>
                                </div>
                            )
                        }
                    </div>
                    <div className="form-group">
                        <label htmlFor="">{this.props.t('propose.content.choice_types',"")}</label>
                        <div>
                            <Select
                                styles={customDropdownStyles}
                                value={this.state.selectedChoiceType}
                                onChange={this.handleChangeChoiceType}
                                options={defaultChoiceTypes}
                                placeholder={this.props.t('propose.content.select_choice_type',"Select answer format best suited to your question...")}
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <em>{this.props.t('propose.content.disclaimer',"By submitting a question you agree to have your full name posted publicly next to your question on the Home Page.")}</em>
                    </div>
                    <div className="cta__wrapp pv-md">
                        <a href="javascript:void(0);" className="cta--normal cta--green" onClick={this.proposeQuestion}>
                            {this.props.t('propose.content.propose_question',"Submit the question")}
                        </a>
                    </div>
                </div>
                <div className="cta__wrapp" style={{paddingBottom: '15px'}}>
                    <strong>{this.props.t('propose.content.my_questions',"My questions")}</strong>
                </div>
                <div>
                    {proposedQuestionsTable}
                </div>
                <SidebarRightMobile/>
            </section>
        );
    };

    proposeQuestion = () => {
        const {toastManager} = this.props;
        const {card, selectedChoiceType} = this.state;

        if (!card.text) {
            toastManager.add(this.props.t('propose.messages.question_text_empty',"The question text is empty, please add text!"), {
                appearance: 'warning',
                autoDismiss: window.questiaSpecificWebsiteConfig.NOTIFICATIONS_AUTO_DISMISS,
                autoDismissTimeout: window.questiaSpecificWebsiteConfig.NOTIFICATIONS_AUTO_DISMISS_TIMEOUT
            });
            return ;
        }

        if (!selectedChoiceType) {
            toastManager.add(this.props.t('propose.messages.question_choice_type_not_selected',"The question scale is not chosen!"), {
                appearance: 'warning',
                autoDismiss: window.questiaSpecificWebsiteConfig.NOTIFICATIONS_AUTO_DISMISS,
                autoDismissTimeout: window.questiaSpecificWebsiteConfig.NOTIFICATIONS_AUTO_DISMISS_TIMEOUT
            });
            return ;
        }

        if (selectedChoiceType.value === null && card.choices.find(choice => choice.value === "")) {
            toastManager.add(this.props.t('propose.messages.question_choice_text_empty',"At least one choice text is empty, please add text!"), {
                appearance: 'warning',
                autoDismiss: window.questiaSpecificWebsiteConfig.NOTIFICATIONS_AUTO_DISMISS,
                autoDismissTimeout: window.questiaSpecificWebsiteConfig.NOTIFICATIONS_AUTO_DISMISS_TIMEOUT
            });
            return ;
        }

        let questionPayload = {
            text: card.text,
            choiceTypeId: selectedChoiceType.value,
            questionType: card.type,
            choices: selectedChoiceType.value === null ? card.choices.map((choice, index) => {
                return {
                    label: choice.value,
                    value: index + 1
                };
            }) : null
        };

        return this.props.globalContext.services.questionsAPI.proposeQuestion(questionPayload,
            (response) => {
                toastManager.add(this.props.t('propose.messages.question_proposed',"The question was proposed!"), {
                    appearance: 'success',
                    autoDismiss: window.questiaSpecificWebsiteConfig.NOTIFICATIONS_AUTO_DISMISS,
                    autoDismissTimeout: window.questiaSpecificWebsiteConfig.NOTIFICATIONS_AUTO_DISMISS_TIMEOUT
                });
                card.text = "";
                this.setState({card: card}, () => {
                    this.fetchProposedQuestions();
                });
            },
            (error) => {
                toastManager.add(this.props.t('propose.messages.question_not_proposed',"Question proposal error!"), {
                    appearance: 'error',
                    autoDismiss: window.questiaSpecificWebsiteConfig.NOTIFICATIONS_AUTO_DISMISS,
                    autoDismissTimeout: window.questiaSpecificWebsiteConfig.NOTIFICATIONS_AUTO_DISMISS_TIMEOUT
                });
                card.text = "";
                this.setState({card: card});
            }
        );
    };

    buildChoicesPreview = (card, selectedChoiceType) => {
        if (!card.choices || card.choices.length === 0)
            return null;
        if(selectedChoiceType.value !== null) {
            return (
                <ChoiceRenderer card={card}/>
            )
        }
        else
            return (
                <CustomChoiceSection
                    choices={card.choices}
                    choicesError={this.state.choicesError}
                    questionType={card.type}
                    onDeleteClick={this.onDeleteClick}
                    onAddOptionClick={this.onAddOptionClick}
                    handleChoiceTextChange={this.handleChoiceTextChange}
                    onDragEnd={this.onDragEnd}
                    handleQuestionTypeRadio={this.handleQuestionTypeRadio}
                />
            );
    };
}

export default withToastManager(withGlobalContext(withTranslation()(ProposeQuestion)));