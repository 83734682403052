import React, {Component} from 'react';
import PropTypes from "prop-types";

export default class QuestionVerticalButtonsChoice extends Component {
    static propTypes = {
        choice: PropTypes.object.isRequired,
        handleAnswerSelection: PropTypes.func.isRequired,
        usesChoiceImages: PropTypes.bool.isRequired
    };

    constructor(props) {
        super(props);
    }

    render() {
	    const {choice, usesChoiceImages, cardId} = this.props;
	    const imageLink = usesChoiceImages ? ("/rest/widget/choice/" + choice.id + "/image") : "";

	    let inputName = "group" + choice.id, labelId = "r_" + cardId + "_" + choice.id;

        return (
            <label className="align-self-stretch">
                <input type="radio" name={inputName} id={labelId} value={choice.value} onClick={this.props.handleAnswerSelection}/>
                <div className="d-flex flex-column align-items-center label-box">
                    { parseInt(choice.haveMedia) ? <img className="img-fluid" src={imageLink}/> : ""}
                    <span>{choice.label}</span>
                </div>
            </label>
        );
    }


}