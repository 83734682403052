import React, {Component} from 'react';
import PropTypes from "prop-types";
import queryString from 'query-string';
import withHeader from "../../hocs/withHeader";
import { withToastManager } from 'react-toast-notifications';
import GoogleAuthComp from "./GoogleAuthComp";
import FacebookAuthComp from "./FacebookAuthComp";
import VkontakteAuthComp from "./VkontakteAuthComp";
import withGlobalContext from "../../hocs/withGlobalContext";
import { withTranslation } from 'react-i18next';
import AituAuthComp from "./AituAuthComp";
import LoadingIndicator from "../../meta/LoadingIndicator";


class SocialAuthPage extends Component {
    static propTypes = {
        globalContext: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);

        const values = queryString.parse(props.location.search);

        if((typeof values.state === "undefined") || (values.state && !["facebook", "google", "vkontakte", "login_method_aitu"].includes(values.state))) {
            this.props.history.push("/login");
        }

        this.state = {
            loginType: values.state,
        };
    }

    render() {

        var toRender;

        switch(this.state.loginType) {
            case "google":
                toRender = (<GoogleAuthComp/>);
                break;
            case "facebook":
                toRender = (<FacebookAuthComp/>);
                break;
            case "vkontakte":
                toRender = (<VkontakteAuthComp/>);
                break;
            case "login_method_aitu":
                toRender = (<AituAuthComp/>);
                break;
            default:
                toRender = (<LoadingIndicator/>);
        }

        return toRender;
    }
}

export default withGlobalContext(withToastManager(withHeader(withTranslation()(SocialAuthPage))));