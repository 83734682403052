import React, {Component} from 'react';
import Footer from "../layout/Footer/Footer";

function withFooter(WrappedPageComponent) {
    return class extends Component {
        constructor(props) {
            super(props)
        }

        render() {
            return (
                <React.Fragment>
                    <WrappedPageComponent {...this.props}/>
                    <Footer/>
                </React.Fragment>
            );
        }
    }
}

export default withFooter;