import React from 'react';
import {GlobalContext} from "../../GlobalContext";

function withGlobalContext(Component) {
    return function WrapperComponent(props) {
        return (
            <GlobalContext.Consumer>
                {globalContext => <Component {...props} globalContext={globalContext} />}
            </GlobalContext.Consumer>
        );
    };
}

export default withGlobalContext;