import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";

class PromoWidget extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Link to="/app/spendpoints" class="box box--text bg--white text--black text-center">
                <span className="box__text pt-sm pb-xs">
                    <b>{this.props.t('sidebarRight.widget_promo.text1_1',"Questia's winter surprise!")}</b>&nbsp;{this.props.t('sidebarRight.widget_promo.text1_2',"🎁❄ More chances to win in the monthly draw!")}
                </span>
                <span className="box__text pb-xs">
                    {this.props.t('sidebarRight.widget_promo.text2_1', "During")}&nbsp;<b>{this.props.t('sidebarRight.widget_promo.text2_2', "17-31 december 2020:")}</b>
                </span>

                <span className="box__text">
                    <b>{this.props.t('sidebarRight.widget_promo.text3_1', "5 tickets")}</b>&nbsp;{this.props.t('sidebarRight.widget_promo.text3_2', "are worth")}&nbsp;<b className="text--red">{this.props.t('sidebarRight.widget_promo.text3_3', "200 points")}</b>
                </span>

                <span className="box__text">
                    <b>{this.props.t('sidebarRight.widget_promo.text4_1', "10 tickets")}</b>&nbsp;{this.props.t('sidebarRight.widget_promo.text4_2', "are worth")}&nbsp;<b className="text--red">{this.props.t('sidebarRight.widget_promo.text4_3', "300 points")}</b>
                </span>

                <img className="box__image pt-sm pb-sm" src={"specific/images/promo_widget_christmas.png"} alt=""/>
            </Link>
        );
    }
}

export default withTranslation()(PromoWidget);