import React, {Component} from 'react';
import PropTypes from "prop-types";
import { withTranslation } from 'react-i18next';

class Agreement extends Component {
    static propTypes = {
        handleCheckboxEvent: PropTypes.func.isRequired,
        agreeTerms: PropTypes.bool.isRequired,
        agreeProcessing: PropTypes.bool.isRequired,
        uniqueId: PropTypes.string.isRequired,
        isModalAgreement: PropTypes.bool.isRequired
    };

    constructor(props) {
        super(props);
    }

    render() {

        var { agreeTerms, agreeProcessing } = this.props;
        var { center, isModalAgreement } = this.props;

        if (isModalAgreement) {
            return (
                <React.Fragment>
                    <label className="check" htmlFor={"modal_first_" + this.props.uniqueId}>
                        <input className="check__input"
                               type="checkbox"
                               id={"modal_first_" + this.props.uniqueId}
                               name="agreeTerms"
                               value={agreeTerms}
                               onChange={this.props.handleCheckboxEvent}
                               checked={agreeTerms}
                        />
                        <span id="spanCheckAgreement1Id" className="check__check checkbox"></span>
                        {this.props.t('login.content.agreement_terms_text',"I am over 18 years old, I have read, understood and I agree with")}<a className="gdpr-terms" href="/terms" target="_blank">{this.props.t('login.content.agreement_terms_link',"the General Terms and Conditions of the website")}</a>
                    </label>
                    <label className="check" htmlFor={"modal_second_" + this.props.uniqueId}>
                        <input className="check__input"
                               type="checkbox"
                               id={"modal_second_" + this.props.uniqueId}
                               name="agreeProcessing"
                               value={agreeProcessing}
                               onChange={this.props.handleCheckboxEvent}
                               checked={agreeProcessing}
                        />
                        <span id="spanCheckAgreement2Id"  className="check__check checkbox"></span>
                        {this.props.t('login.content.agreement_privacy_text',"I have read, understood and I agree with ")}<a className="gdpr-terms" href="/privacy" target="_blank">{this.props.t('login.content.agreement_privacy_link',"the Policy concerning Personal Data")}</a>
                    </label>
                </React.Fragment>
            );
        }
        else {
            return (
                <React.Fragment>
                    <div className={center ? "form-group form-flex form-flex--center": "form-group form-flex pt-sm"}>
                        <label className="check-container" htmlFor={"checkAgreement1_" + this.props.uniqueId}>
                            <input className="check__input"
                                   type="checkbox"
                                   id={"checkAgreement1_" + this.props.uniqueId}
                                   name="agreeTerms"
                                   value={agreeTerms}
                                   onChange={this.props.handleCheckboxEvent}
                                   checked={agreeTerms}
                            />
                            <span className="check__check"></span>
                            {this.props.t('login.content.agreement_terms_text',"I am over 18 years old, I have read, understood and I agree with")}<a className="gdpr-terms" href="/terms" target="_blank">{this.props.t('login.content.agreement_terms_link',"the General Terms and Conditions of the website")}</a>
                        </label>
                    </div>
                    <div className={center ? "form-group form-flex form-flex--center": "form-group form-flex"}>
                        <label className="check-container" htmlFor={"checkAgreement2_" + this.props.uniqueId}>
                            <input className="check__input"
                                   type="checkbox"
                                   id={"checkAgreement2_" + this.props.uniqueId}
                                   name="agreeProcessing"
                                   value={agreeProcessing}
                                   onChange={this.props.handleCheckboxEvent}
                                   checked={agreeProcessing}
                            />
                            <span className="check__check"></span>
                            {this.props.t('login.content.agreement_privacy_text',"I have read, understood and I agree with ")}<a className="gdpr-terms" href="/privacy" target="_blank">{this.props.t('login.content.agreement_privacy_link',"the Policy concerning Personal Data")}</a>
                        </label>
                    </div>
                </React.Fragment>
            );
        }
    }
}

export default withTranslation()(Agreement);


