import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import {CookiesProvider} from 'react-cookie';
import './css/bootstrap.css';
import './index.css';
import WebFont from 'webfontloader';
import App from './App';
import * as serviceWorker from './serviceWorker';
import GlobalProvider from './GlobalProvider'
import ErrorBoundary from "./components/meta/ErrorBoundary";
import {BrowserRouter, Route, Switch, Redirect} from 'react-router-dom';
import CardsProvider from "./components/services/CardsProvider";
import AuthenticationProvider from "./components/services/Authentication/AuthenticationProvider";
import SecurityWrapper from "./components/services/Security/SecurityWrapper";
import {ToastProvider} from 'react-toast-notifications';
import withDummySidebars from "./components/hocs/withDummySidebars";
import withHeader from "./components/hocs/withHeader";
import {initializeFirebase} from "./push-notification";
import ScrollToTop from "./components/meta/ScrollToTop";
import withFooter from "./components/hocs/withFooter";
import TagManager from "react-gtm-module";
import './i18n';
import LoadingIndicator from './components/meta/LoadingIndicator';
import SocialAuthPage from './components/layout/Social/SocialAuthPage';
import './components/lib/localeIndex';
import {initFacebookSdk, fbAsyncInit} from "./components/lib/facebookInit";
import SecurityWrapperEmbed from "./components/services/Security/SecurityWrapperEmbed";

window.fbAsyncInit = fbAsyncInit;
initFacebookSdk();

WebFont.load({
    google: {
        families: ['Crete+Round:400,400i','Raleway:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i:latin-ext',
            'Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i:latin-ext',
            'Prompt:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i:latin-ext',
            'Roboto+Slab:400,400i', 'Roboto:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i:latin-ext']
    }
});

document.body.classList.add('sticky-header', 'bg--gray');

// LAZY IMPORTS
const PublicFaqPageComponent = withHeader(withFooter(withDummySidebars(React.lazy(() => import("./components/layout/Faq/Faq")))));
const PublicTermsAndConditionsPageComponent = withHeader(withFooter(withDummySidebars(React.lazy(() => import("./components/layout/TermsAndConditions/TermsAndConditions")))));
const PublicHowPageComponent = withHeader(withFooter(withDummySidebars(React.lazy(() => import("./components/layout/HowItWorks/HowItWorks")))));
const PublicPrivacyPolicyComponent = withHeader(withFooter(withDummySidebars(React.lazy(() => import("./components/layout/Privacy/PrivacyPolicy")))));
const PublicAboutUsComponent = withHeader(withFooter(withDummySidebars(React.lazy(() => import("./components/layout/AboutUs/AboutUs")))));
const PublicContactComponent = withHeader(withFooter(withDummySidebars(React.lazy(() => import("./components/layout/Contact/Contact")))));
const PublicPrizesPageComponent = withHeader(withFooter(withDummySidebars(React.lazy(() => import("./components/layout/Raffle/PrizesPage")))));
const PublicPageNotFoundComponent = withHeader(withFooter(withDummySidebars(React.lazy(() => import("./components/meta/PageNotFound")))));
const PublicDisclaimerComponent = withHeader(withDummySidebars(React.lazy(() => import("./components/layout/Disclaimer/Disclaimer"))));
const LandingPage = React.lazy(() => import("./components/LandingPage"));
const Login = React.lazy(() => import("./components/layout/Authentication/Login"));
// const SocialAuthPage = React.lazy(() => import("./components/layout/Social/SocialAuthPage"));
const SignInLandingPage = React.lazy(() => import('./components/layout/LandingPages/SignInLandingPage'));
const SignUpLandingPage = React.lazy(() => import('./components/layout/LandingPages/SignUpLandingPage'));
const Register = React.lazy(() => import("./components/layout/Authentication/Register"));
const RegisterSuccess = React.lazy(() => import("./components/layout/Authentication/RegisterSuccess"));
const ActivateAccount = React.lazy(() => import("./components/layout/Authentication/ActivateAccount"));
const PasswordForgot = React.lazy(() => import("./components/layout/Authentication/PasswordForgot"));
const PasswordChange = React.lazy(() => import("./components/layout/Authentication/PasswordChange"));
const PublicCardPage = React.lazy(() => import("./components/layout/CardPages/PublicCardPage"));
const LiveSurveyEmbedPage = React.lazy(() => import("./components/layout/LiveSurvey/LiveSurveyEmbedPage"));
const Unsubscribe = React.lazy(() => import("./components/layout/User/Unsubscribe"));
const CallbackPage = React.lazy(() => import("./components/layout/CallbackPages/CallbackPage"));
const RestrictPage = withDummySidebars(React.lazy(() => import("./components/layout/Restrict/RestrictPage")));

const tagManagerArgs = {
    gtmId: window.questiaSpecificWebsiteConfig.GTM_ID,
    dataLayerName: 'dataLayer',
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
    <Suspense fallback={<LoadingIndicator/>}>
        <BrowserRouter>
            <ScrollToTop>
                <CookiesProvider>
                    <ErrorBoundary boundaryName="App Component">
                        <ToastProvider placement="bottom-center">
                            <GlobalProvider>
                                <AuthenticationProvider>
                                    <CardsProvider>
                                            <Switch>
                                                <Route exact path="/" render={(props) => (<LandingPage {...props}/>)}/>

                                                {/* double slashes "//" redirect workaround */}
                                                <Redirect from='//' to='/' />

                                                {/* Workaround for workbox redirecting service-worker path to /index.html */}
                                                <Redirect from='/index.html' to='/' />
                                                {/* ----------------------------- */}

                                                {/* Share card path redirect */}
                                                <Route path='/rest/shareCard/:cardId' render={(props) => (<Redirect to={'/publicCardPage/' + props.match.params.cardId}/>)}/>
                                                {/* ----------------------------- */}

                                                <Route path="/app" render={(props) => (
                                                    <SecurityWrapper>
                                                        <App {...props}/>
                                                    </SecurityWrapper>
                                                )}/>
                                                <Route path="/login" render={(props) => (<Login {...props}/>)}/>
                                                <Route path="/socialAuth" render={(props) => (<SocialAuthPage {...props}/>)}/>
                                                <Route path="/register" render={(props) => (<Register {...props}/>)}/>
                                                <Route path="/registerSuccess" render={(props) => (<RegisterSuccess {...props}/>)}/>
                                                <Route path="/activate" render={(props) => (<ActivateAccount {...props}/>)}/>
                                                <Route path="/passForgot" render={(props) => (<PasswordForgot {...props}/>)}/>
                                                <Route path="/passChange" render={(props) => (<PasswordChange {...props}/>)}/>
                                                <Route path="/sign-in" render={(props) => (<SignInLandingPage {...props}/>)}/>
                                                <Route path="/sign-up" render={(props) => (<SignUpLandingPage {...props}/>)}/>
                                                <Route path="/publicCardPage/:id" render={(props) => (
                                                    <SecurityWrapperEmbed>
                                                        <PublicCardPage {...props}/>
                                                    </SecurityWrapperEmbed>
                                                )}/>
                                                <Route path="/live-survey-embed/:id" render={(props) => (
                                                    <SecurityWrapperEmbed>
                                                        <LiveSurveyEmbedPage {...props}/>
                                                    </SecurityWrapperEmbed>
                                                )}/>
                                                <Route path={"/faq"} component={PublicFaqPageComponent}/>
                                                <Route path={"/terms"} component={PublicTermsAndConditionsPageComponent}/>
                                                <Route path={"/how"} component={PublicHowPageComponent}/>
                                                <Route path={"/privacy"} component={PublicPrivacyPolicyComponent}/>
                                                <Route path={"/aboutUs"} component={PublicAboutUsComponent}/>
                                                <Route path={"/contact"} component={PublicContactComponent}/>
                                                <Route path={"/prizes"} component={PublicPrizesPageComponent}/>
                                                <Route path={"/disclaimer"} component={PublicDisclaimerComponent}/>
                                                <Route path="/unsubscribe" render={(props) => (<Unsubscribe {...props}/>)}/>
                                                {
                                                    window.questiaSpecificWebsiteConfig.VK_APP_ENABLED &&
                                                    <Route path={"/callback"} render={(props) => (<CallbackPage {...props}/>)}/>
                                                }
                                                <Route path={"/restrict"} component={RestrictPage}/>
                                                <Route render={(props) => (<PublicPageNotFoundComponent {...props}/>)}/>
                                            </Switch>
                                    </CardsProvider>
                                </AuthenticationProvider>
                            </GlobalProvider>
                        </ToastProvider>
                    </ErrorBoundary>
                </CookiesProvider>
            </ScrollToTop>
        </BrowserRouter>
    </Suspense>
    , document.getElementById('root')
);
initializeFirebase();
// serviceWorker.register();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
