import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons/faTimes";
import {Link} from "react-router-dom";


class PrivacyMessageBox extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div style={{zIndex: "1000", position: "fixed", width: "100%", opacity: 0.8, bottom: "1%"}}>
                <div style={{
                    background: "black",
                    maxWidth: "1300px",
                    color: "white",
                    padding: "15px",
                    borderRadius: "20px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    opacity: 1,
                    display: "flex",
                    fontSize: "12px"
                }}>
                    <div style={{textAlign: "center"}}>
                        <p>{this.props.t('privacy_message_box.content.text', "We use session cookies that allow you to browse the website and use its features, such as accessing secure areas of the site. We also use third-party cookies - to collect information about how you use the website, like which pages you visited and which links you clicked on. None of this information can be used to identify you. More about cookies in our: ")}</p>
                        <p>
                            <Link className="color--green" to="/terms" onClick={this.props.handleCloseMessageBox}>
                                {this.props.t('privacy_message_box.content.terms_conditions', "Terms & Conditions")}
                            </Link>.
                        </p>
                    </div>
                    <a href="javascript:void(0);" style={{paddingLeft: "20px", color: "white", height: "20px"}}
                       onClick={this.props.handleCloseMessageBox}><FontAwesomeIcon icon={faTimes}/></a>
                </div>
            </div>
        );
    }
}

export default withTranslation()(PrivacyMessageBox);