import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCopyright, faUser, faBars, faGlobe} from '@fortawesome/free-solid-svg-icons';
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import withGlobalContext from "../../hocs/withGlobalContext";
import withAuthenticationContext from "../../hocs/withAuthenticationContext";
import UserInfo from "../User/UserInfo";
import PWAInstall from "../../pwaInstall/PWAInstall";
import { withTranslation } from 'react-i18next';
import { withToastManager } from 'react-toast-notifications';
import LoadingIndicator from "../../meta/LoadingIndicator";
import axios from "axios";
import withCardsContext from "../../hocs/withCardsContext";
import {faFacebookSquare, faInstagramSquare, faTwitterSquare} from "@fortawesome/free-brands-svg-icons";
import QuestiaUtils from "../../utils/QuestiaUtils";

class Header extends React.Component {
    static propTypes = {
        globalContext: PropTypes.object.isRequired,
        authenticationContext: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            languages: [],
            disableChangeLanguage: false,
            collapse: false,
            leftMenuOpen: false,
            rightMenuOpen: false,
            activeNavOptionIndex: 0
        };
    }

    componentDidMount() {
        axios.get('getLanguages/')
            .then(response => {
                this.setState({languages: response.data});
            })
            .catch(response => {
                console.log("Error loading languages!");
            });
    }


    toggle = () => {
        this.setState({collapse: !this.state.collapse});
    };

    toggleLeftMenu = () => {
        this.setState((prevState) => ({leftMenuOpen: !prevState.leftMenuOpen}))
    };

    toggleRightMenu = () => {
        this.setState((prevState) => ({rightMenuOpen: !prevState.rightMenuOpen}));
        if(document.body.classList.contains("noscroll")) {
            document.body.classList.remove("noscroll");
        }
        else {
            document.body.classList.add("noscroll");
        }
    };

    switchActiveOptionIndex = (index) => {
        this.setState({activeNavOptionIndex: index})
    };

    getNavMenuOptionClassByActiveIndex = (optionIndex) => {
        return optionIndex === this.state.activeNavOptionIndex ? "nav__item nav__item--active" : "nav__item";
    };

    handleLogoutSteps = () => {
        this.props.authenticationContext.functions.clearCredentials();
        window.location = '/login';
    };

    handleLogout = () => {
        if (window.FB) {
            window.FB.getLoginStatus((r1) => {
                if (r1 && r1.status === 'connected') {
                    window.FB.logout((r2) => {
                        this.handleLogoutSteps();
                    })
                } else {
                    this.handleLogoutSteps();
                }
            });
        } else {
            this.handleLogoutSteps();
        }
    };

    changeLanguage = (language) => {
        if (this.state.disableChangeLanguage) {
            return;
        }

        this.props.i18n.changeLanguage(language.panelDirectoryName, (err, t) => {
            // console.log(err);
        });

        this.props.globalContext.functions.clearLanguageCookie();
        this.props.globalContext.functions.setLanguageCookie(language.panelDirectoryName);
        this.props.globalContext.functions.setLanguage(language.panelDirectoryName);

        if (this.props.globalContext.isUserDataLoaded) {
            var user = {
                user_id: this.props.globalContext.globals.userId,
                user_language_id: language.languageId
            };

            this.setState({disableChangeLanguage: true},
                () => {
                    this.props.globalContext.services.userAPI.updateUser(user,
                        (success) => {
                            // this.props.toastManager.add(this.props.t('settings.messages.password_change_success',"Password changed successfully!"), {
                            this.props.toastManager.add(this.props.t('multiLanguage.messages.language_change_success',"User language successfully changed!"), {
                                appearance: 'success',
                                autoDismiss: window.questiaSpecificWebsiteConfig.NOTIFICATIONS_AUTO_DISMISS,
                                autoDismissTimeout: window.questiaSpecificWebsiteConfig.NOTIFICATIONS_AUTO_DISMISS_TIMEOUT
                            });
                            this.setState({disableChangeLanguage: false});
                            this.props.globalContext.globals.updateUserInfo();
                            this.props.cardsContext.functions.fetchActiveSurveys();
                        },
                        (error) => {
                            // this.props.toastManager.add(this.props.t('settings.messages.password_change_error',"Error changing password, contact Questia!"), {
                            if(error.response.data.code === 4) {
                                this.props.toastManager.add(this.props.t('multiLanguage.messages.language_already_selected',"User panel language is already the selected one!"), {
                                    appearance: 'warning',
                                    autoDismiss: window.questiaSpecificWebsiteConfig.NOTIFICATIONS_AUTO_DISMISS,
                                    autoDismissTimeout: window.questiaSpecificWebsiteConfig.NOTIFICATIONS_AUTO_DISMISS_TIMEOUT
                                });
                            } else {
                                this.props.toastManager.add(this.props.t('multiLanguage.messages.language_change_error',"Error updating user panel language!"), {
                                    appearance: 'error',
                                    autoDismiss: window.questiaSpecificWebsiteConfig.NOTIFICATIONS_AUTO_DISMISS,
                                    autoDismissTimeout: window.questiaSpecificWebsiteConfig.NOTIFICATIONS_AUTO_DISMISS_TIMEOUT
                                });
                            }
                            this.setState({disableChangeLanguage: false});
                        }
                    );
                })
        }
    };

    generateSelectLanguageDropdownDesktop = (languages) => {

        let languagesHtmlList = languages.map((language, i , array) => {
            return (
                <li className="dropdown__item"
                    onClick={() => this.changeLanguage(language)}>
                    <a className="dropdown__link" href="javascript:void(0);"> {language.uiName} </a>
                </li>
            );
        });

        return (
            <li className="nav__item nav__item--language">
                <a className="nav__item__link" href="javascript:void(0);"><FontAwesomeIcon icon={faGlobe}/></a>
                <ul className="dropdown">
                    {languages.length === 0 ? <LoadingIndicator/> : languagesHtmlList}
                </ul>
            </li>
        );
    };

    mobileLanguageSelectorOnChangeEventHandler = (event) => {
        var selectedLanguage = this.state.languages.find(elem => elem.languageId === parseInt(event.target.value));
        this.changeLanguage(selectedLanguage);
    };

    generateSelectLanguageDropdownMobile = (languages) => {
        let languagesHtmlList = languages.map((language, i , array) => {
            var userProfileLang = this.props.globalContext.userInfo.language;
            var html;
            if(typeof userProfileLang !== "undefined" && userProfileLang.lang_id === language.languageId) {
                html = (<option value={language.languageId} selected>{language.uiName}</option>);
            } else {
                var cookieLanguage = this.props.globalContext.functions.getLanguageCookie();
                if(cookieLanguage === language.panelDirectoryName) {
                    html = (<option value={language.languageId} selected>{language.uiName}</option>);
                } else {
                    html = (<option value={language.languageId}>{language.uiName}</option>);
                }
            }
            return html;
        });

        return (
            <li className="nav__item">
                <span className="nav__item__language">
                    <select onChange={this.mobileLanguageSelectorOnChangeEventHandler}>
                        {languagesHtmlList}
                    </select>
                </span>
            </li>
        );
    };

    render() {
        const {globals} = this.props.globalContext;
        const {userInfo} = this.props.globalContext;
        const {isUserDataLoaded} = this.props.globalContext;

        const hideBlockedHTML = window.questiaSpecificWebsiteConfig.BLOCKING_PROFILE && !globals.hasUserCompletedProfile;

        const selectLanguageDropdownDesktop = this.generateSelectLanguageDropdownDesktop(this.state.languages);
        const selectLanguageDropdownMobile = this.generateSelectLanguageDropdownMobile(this.state.languages);

        const domain = window.location.hostname.startsWith("www") ? window.location.hostname.slice(3) : window.location.hostname;
        const predictorSiteUrl = "https://predictor" + (domain.startsWith(".") ? "" : ".") + domain;

        return (
            <header className={this.state.rightMenuOpen || this.state.leftMenuOpen ? "menu-mobile" : ""}>
                <div className={isUserDataLoaded ? "container" : "container container--home"}>

                    {/* ------------- NAVBAR MENU DESKTOP ------------- */}
                    <div className={isUserDataLoaded ? "header-desktop row" : "header-desktop"}>
                        <div className={isUserDataLoaded ? "logo" : "logo homepage"}>
                            <a href={isUserDataLoaded ? "/app/" : "/"}><img
                                src={isUserDataLoaded ? "specific/images/logo_questia_mobile.png" : "specific/images/logo_questia.png"}
                                alt="Questia"></img></a>
                        </div>
                        <nav className={this.state.rightMenuOpen ? "main-nav main-nav--desktop open" : "main-nav main-nav--desktop"}>
                            <ul className="nav">
                                {isUserDataLoaded ?
                                    !hideBlockedHTML &&
                                    (
                                        <li className={this.getNavMenuOptionClassByActiveIndex(0)}
                                            onClick={() => this.switchActiveOptionIndex(0)}><Link
                                            to="/app/">{this.props.t('header.content.timeline_button',"Home")}</Link>
                                        </li>
                                    )
                                    :
                                    (
                                        <li className={this.getNavMenuOptionClassByActiveIndex(0)}
                                            onClick={() => this.switchActiveOptionIndex(0)}><Link
                                            to="/">{this.props.t('header.content.timeline_button',"Home")}</Link>
                                        </li>
                                    )
                                }
                                <li className={this.getNavMenuOptionClassByActiveIndex(1)}
                                    onClick={() => this.switchActiveOptionIndex(1)}>
                                    <Link to={isUserDataLoaded ? "/app/aboutUs" : "/aboutUs"} >{this.props.t('header.content.aboutUs_button',"About us")}</Link>
                                </li>
                                {isUserDataLoaded &&
                                (
                                    window.questiaSpecificWebsiteConfig.ABOUT_YOU_PAGE_ACTIVE &&
                                    (<li className={this.getNavMenuOptionClassByActiveIndex(2)}
                                         onClick={() => this.switchActiveOptionIndex(2)}>
                                        <Link
                                            to="/app/aboutYou">{this.props.t('header.content.aboutYou_button', "About you")}</Link>
                                    </li>)
                                )}
                                <li className={this.getNavMenuOptionClassByActiveIndex(3)}
                                    onClick={() => this.switchActiveOptionIndex(3)}>
                                    <Link to={isUserDataLoaded ? "/app/how" : "/how"}>{this.props.t('header.content.how_button',"How it works")}</Link>
                                </li>
                                <li className={this.getNavMenuOptionClassByActiveIndex(4)}
                                    onClick={() => this.switchActiveOptionIndex(4)}><Link
                                    to={isUserDataLoaded ? "/app/prizes" : "/prizes"}>{this.props.t('header.content.prizes_button',"Prizes")}</Link>
                                </li>
                                {!isUserDataLoaded &&
                                    (
                                        <li className={this.getNavMenuOptionClassByActiveIndex(5)}
                                            onClick={() => this.switchActiveOptionIndex(5)}><Link
                                            to={"/login"}>{this.props.t('header.content.login_button',"Log in")}</Link>
                                        </li>
                                    )
                                }

                                {window.questiaSpecificWebsiteConfig.MULTI_LANGUAGE && selectLanguageDropdownDesktop}

                                {isUserDataLoaded &&
                                (
                                    <li className="nav__item nav__item--profile">
                                        <a className="nav__item__link" href="javascript:void(0);"> <FontAwesomeIcon icon={faBars}/> </a>
                                        <ul className="dropdown">
                                            {!hideBlockedHTML &&
                                            <li className="dropdown__item"
                                                onClick={() => this.switchActiveOptionIndex(6)}>
                                                <Link className="dropdown__link" to="/app/history">{this.props.t('header.content.history_button',"My activity")}</Link>
                                            </li>
                                            }
                                            <li className="dropdown__item"
                                                onClick={() => this.switchActiveOptionIndex(7)}>
                                                <Link className="dropdown__link" to="/app/settings">{this.props.t('header.content.settings_button',"Account Settings")}</Link>
                                            </li>
                                            <li className="dropdown__item"
                                                onClick={() => this.switchActiveOptionIndex(8)}>
                                                <Link className="dropdown__link" to="/app/profile">{this.props.t('header.content.profile_button',"Profile settings")}</Link>
                                            </li>

                                            <li className="dropdown__item"
                                                onClick={() => {
                                                    this.switchActiveOptionIndex(9);
                                                    this.handleLogout()
                                                }}><a className="dropdown__link" href="javascript:void(0)" >{this.props.t('header.content.logout_button',"Log out")}</a>
                                            </li>
                                        </ul>
                                    </li>
                                )}


                            </ul>
                        </nav>
                    </div>

                    <div className="header-mobile">

                        {/* ------------- LEFT MENU BUTTON MOBILE ------------- */}
                        {isUserDataLoaded &&
                        (
                            <div onClick={() => {
                                this.toggleLeftMenu()
                            }} className="profile-nav">
                                {/*<i className="fa fa-user"></i>*/}
                                <FontAwesomeIcon icon={faUser}/>
                            </div>
                        )}

                        {/* ------------- QUESTIA LOGO ------------- */}
                        <div className="logo">
                            <Link to={isUserDataLoaded ? "/app/" : "/"}><img src={"specific/images/logo_questia_mobile.png"} alt="Questia"/></Link>
                        </div>

                        {/* ------------- RIGHT MENU BUTTON MOBILE ------------- */}
                        <div onClick={() => {
                            this.toggleRightMenu()
                        }}
                             className={this.state.rightMenuOpen ? "burger-menu open" : "burger-menu"}>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>

                        {/* ------------- RIGHT MENU MOBILE (NAVBAR MENU MOBILE VERSION) ------------- */}
                        <nav className={this.state.rightMenuOpen ? "main-nav main-nav--mobile open" : "main-nav main-nav--mobile"}>
                            <ul className="nav">
                                {window.questiaSpecificWebsiteConfig.MULTI_LANGUAGE && selectLanguageDropdownMobile}
                                {isUserDataLoaded ?
                                    !hideBlockedHTML &&
                                    (
                                        <li className={this.getNavMenuOptionClassByActiveIndex(0)}
                                            onClick={() => {
                                                this.switchActiveOptionIndex(0);
                                                this.toggleRightMenu()
                                            }}><Link to="/app/">{this.props.t('header.content.timeline_button',"Home")}</Link>
                                        </li>
                                    )
                                    :
                                    (
                                        <li className={this.getNavMenuOptionClassByActiveIndex(0)}
                                            onClick={() => {
                                                this.switchActiveOptionIndex(0);
                                                this.toggleRightMenu()
                                            }}><Link to="/">{this.props.t('header.content.timeline_button',"Home")}</Link>
                                        </li>
                                    )
                                }
                                <li className={this.getNavMenuOptionClassByActiveIndex(1)}
                                    onClick={() => {
                                        this.switchActiveOptionIndex(1);
                                        this.toggleRightMenu()
                                    }}>
                                    <Link to={isUserDataLoaded ? "/app/aboutUs" : "/aboutUs"} >{this.props.t('header.content.aboutUs_button',"About us")}</Link>
                                </li>
                                {
                                    isUserDataLoaded && window.questiaSpecificWebsiteConfig.ABOUT_YOU_PAGE_ACTIVE &&
                                    <li className={this.getNavMenuOptionClassByActiveIndex(2)}
                                        onClick={() => {
                                            this.switchActiveOptionIndex(2);
                                            this.toggleRightMenu()
                                        }}>
                                        <Link to="/app/aboutYou">{this.props.t('header.content.aboutYou_button',"About you")}</Link>
                                    </li>
                                }
                                <li className={this.getNavMenuOptionClassByActiveIndex(3)}
                                    onClick={() => {
                                        this.switchActiveOptionIndex(3);
                                        this.toggleRightMenu()
                                    }}><Link to={isUserDataLoaded ? "/app/how" : "/how"}>{this.props.t('header.content.how_button',"How it works")}</Link>
                                </li>
                                <li className={this.getNavMenuOptionClassByActiveIndex(4)}
                                    onClick={() => {
                                        this.switchActiveOptionIndex(4);
                                        this.toggleRightMenu()
                                    }}><Link to={isUserDataLoaded ? "/app/prizes" : "/prizes"}>{this.props.t('header.content.prizes_button',"Prizes")}</Link>
                                </li>
                                {!isUserDataLoaded &&
                                    (
                                        <li className={this.getNavMenuOptionClassByActiveIndex(5)}
                                            onClick={() => {
                                                this.switchActiveOptionIndex(5);
                                                this.toggleRightMenu()
                                            }}><Link to={"/login"}>{this.props.t('header.content.login_button',"Log in")}</Link>
                                        </li>
                                    )
                                }

                                {isUserDataLoaded &&
                                (
                                    <React.Fragment>
                                        {!hideBlockedHTML &&
                                        <li className={this.getNavMenuOptionClassByActiveIndex(6)}
                                            onClick={() => {
                                                this.switchActiveOptionIndex(6);
                                                this.toggleRightMenu()
                                            }}><Link
                                            to="/app/history">{this.props.t('header.content.history_button',"My activity")}</Link>
                                        </li>
                                        }
                                        <li className={this.getNavMenuOptionClassByActiveIndex(7)}
                                            onClick={() => {
                                                this.switchActiveOptionIndex(7);
                                                this.toggleRightMenu()
                                            }}><Link
                                            to="/app/settings">{this.props.t('header.content.settings_button',"Account Settings")}</Link>
                                        </li>
                                        <li className={this.getNavMenuOptionClassByActiveIndex(8)}
                                            onClick={() => {
                                                this.switchActiveOptionIndex(8);
                                                this.toggleRightMenu()
                                            }}><Link
                                            to="/app/profile">{this.props.t('header.content.profile_button',"Profile settings")}</Link>
                                        </li>
                                        <li className={this.getNavMenuOptionClassByActiveIndex(9)}
                                            onClick={() => {
                                                this.switchActiveOptionIndex(9);
                                                this.handleLogout()
                                            }}><a
                                            href="javascript:void(0)">{this.props.t('header.content.logout_button',"Log out")}</a>
                                        </li>
                                    </React.Fragment>
                                )}
                            </ul>
                        </nav>

                        {/* ------------- LEFT MENU MOBILE (LEFT SIDEBAR MOBILE VERSION) ------------- */}
                        {isUserDataLoaded &&
                        (
                            <nav
                                className={this.state.leftMenuOpen ? "profile-nav--mobile open" : "profile-nav--mobile"}>
                            <span onClick={() => {
                                this.toggleLeftMenu()
                            }} className="profile-nav--close"></span>
                                <UserInfo globals={globals} userInfo={userInfo}/>

                                <div className="cta__wrapp text-center pv-sm">
                                    <Link to="/app/spendpoints"
                                          className="btn btn--questia btn--outline__yellow py-2 fw-500">{this.props.t('sidebarLeft.content.convert_points_button',"Convert your points")}</Link>
                                </div>
                                <div className="profile__menu text-left">
                                    <a href=""></a>
                                </div>
                                <ul className="profile__menu text-left">
                                    {!hideBlockedHTML &&
                                    <React.Fragment>
                                        <li className="profile__menu__item" onClick={() => this.toggleLeftMenu()}><Link
                                            to="/app/surveys">{this.props.t('sidebarLeft.content.link_text_surveys',"Polls")}</Link></li>
                                        {
                                            window.questiaSpecificWebsiteConfig.PROPOSE_QUESTION_ACTIVE &&
                                            <li className="profile__menu__item" onClick={() => this.toggleLeftMenu()}><Link
                                                to="/app/propose">{this.props.t('sidebarLeft.content.link_text_propose_question',"Submit a question")}</Link></li>
                                        }
                                        <li className="profile__menu__item" onClick={() => this.toggleLeftMenu()}><Link
                                            to="/app/invite">{this.props.t('sidebarLeft.content.link_text_invite_friend',"Invite friends")}</Link></li>
                                        {window.questiaSpecificWebsiteConfig.PREDICTOR_ACTIVE && QuestiaUtils.userHasPredictorRights(this.props.globalContext.globals.level) &&
                                        <li className="profile__menu__item" onClick={() => this.toggleLeftMenu()}><a
                                            href={predictorSiteUrl}>{this.props.t('sidebarLeft.content.link_text_predictor',"Predictor")}</a></li>
                                        }
                                        <li className="profile__menu__item" onClick={() => this.toggleLeftMenu()}>
                                            <a href="javascript:void(0);" >
                                                <PWAInstall>
                                                    {this.props.t('sidebarLeft.content.link_text_install_app',"Install App")}
                                                </PWAInstall>
                                            </a>
                                        </li>
                                    </React.Fragment>
                                    }

                                    <li className="footer__list__item" onClick={() => this.toggleLeftMenu()}><Link
                                        to="/app/contact">{this.props.t('footer.content.link_text_contact',"Contact")}</Link></li>
                                    <li className="footer__list__item" onClick={() => this.toggleLeftMenu()}><Link
                                        to="/app/privacy">{this.props.t('footer.content.link_text_privacy',"Privacy policy")}</Link></li>
                                    <li className="footer__list__item" onClick={() => this.toggleLeftMenu()}><Link
                                        to="/app/terms/">{this.props.t('footer.content.link_text_terms',"Terms and conditions")}</Link></li>
                                    <li className="footer__list__item" onClick={() => this.toggleLeftMenu()}><Link
                                        to="/app/faq">{this.props.t('footer.content.link_text_faq',"FAQ")}</Link></li>
                                    <li>
                                        <ul className="footer__list pl-0 my-2">
                                            <li className="footer__list__item">
                                                {window.questiaSpecificWebsiteConfig.FACEBOOK_PAGE_URL &&
                                                <a target="_blank"
                                                   href={window.questiaSpecificWebsiteConfig.FACEBOOK_PAGE_URL}
                                                   className="footer-social"><i
                                                    className="fa fa-facebook-square"><FontAwesomeIcon
                                                    icon={faFacebookSquare}></FontAwesomeIcon></i></a>
                                                }
                                                {window.questiaSpecificWebsiteConfig.INSTAGRAM_PAGE_URL &&
                                                <a target="_blank"
                                                   href={window.questiaSpecificWebsiteConfig.INSTAGRAM_PAGE_URL}
                                                   className="footer-social"><i className="fa fa-instagram"><FontAwesomeIcon
                                                    icon={faInstagramSquare}></FontAwesomeIcon></i></a>
                                                }
                                                {window.questiaSpecificWebsiteConfig.TWITTER_PAGE_URL &&
                                                <a target="_blank"
                                                   href={window.questiaSpecificWebsiteConfig.TWITTER_PAGE_URL}
                                                   className="footer-social"><i
                                                    className="fa fa-twitter-square"><FontAwesomeIcon
                                                    icon={faTwitterSquare}></FontAwesomeIcon></i></a>
                                                }
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="footer__list__item footer__legal color--blue" onClick={() => this.toggleLeftMenu()}>
                                        <FontAwesomeIcon icon={faCopyright}></FontAwesomeIcon><span className="footer__legal__year"></span>&nbsp; {this.props.t('quests.index.title',"Questia")}
                                    </li>
                                </ul>
                            </nav>
                        )}
                    </div>
                </div>
            </header>
        );
    }
}

export default withToastManager(withGlobalContext(withAuthenticationContext(withCardsContext(withTranslation()(Header)))));