import React from 'react';

class PWA extends React.Component {

    componentDidMount() {

        let deferredInstallPrompt = null;
        const installButton = document.getElementById('butInstall');
        installButton.addEventListener('click', installPWA);

        // Add event listener for beforeinstallprompt event
        window.addEventListener('beforeinstallprompt', saveBeforeInstallPromptEvent);


        /**
         * Event handler for beforeinstallprompt event.
         *   Saves the event & shows install button.
         *
         * @param {Event} evt
         */
        function saveBeforeInstallPromptEvent(evt) {
            // Add code to save event & show the install button.
            deferredInstallPrompt = evt;
            installButton.removeAttribute('hidden');

        }


        /**
         * Event handler for butInstall - Does the PWA installation.
         *
         * @param {Event} evt
         */
        function installPWA(evt) {
            // Add code show install prompt & hide the install button.
            deferredInstallPrompt.prompt();
            // Hide the install button, it can't be called twice.
            evt.srcElement.setAttribute('hidden', true);

            // Log user response to prompt.
            deferredInstallPrompt.userChoice
                .then((choice) => {
                    if (choice.outcome === 'accepted') {
                        console.log('User accepted the A2HS prompt', choice);
                    } else {
                        console.log('User dismissed the A2HS prompt', choice);
                    }
                    deferredInstallPrompt = null;
                });
        }

        // Add event listener for appinstalled event
        window.addEventListener('appinstalled', logAppInstalled);

        /**
         * Event handler for appinstalled event.
         *   Log the installation to analytics or save the event somehow.
         *
         * @param {Event} evt
         */
        function logAppInstalled(evt) {
          // Add code to log the event
          console.log('App was installed.', evt);
        }
    }

    render() {
        return (
            <React.Fragment>

                <div id="butInstall" aria-label="Install" hidden style={{ fontWeight: 'bold', color: '#b9d15e' }}>
                    {this.props.children}
                </div>

            </React.Fragment>
        );
    }
}
export default PWA;