import React, {Component} from 'react';
import {GlobalContext} from './GlobalContext';
import {withCookies, Cookies} from 'react-cookie';
import {instanceOf} from 'prop-types';
import axios from "axios/index";
import { withRouter } from 'react-router-dom';
import UserAPI from "./components/services/API/UserAPI";
import queryString from "query-string";
import { withTranslation } from 'react-i18next';
import QuestionsAPI from "./components/services/API/QuestionsAPI";
import PrivacyMessageBox from "./components/layout/Privacy/PrivacyMessageBox";
import AuthenticationUtils from "./components/utils/AuthenticationUtils";

class GlobalProvider extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);

        const recommendedBy = this.initRecommendedBy();

        this.state = {
            showPrivacyMessage: true,
            mainCssFilePath: "main.css",
            globals: {},
            lang: window.questiaSpecificWebsiteConfig.DEFAULT_LANGUAGE,
            recommendedBy: recommendedBy,
            userInfo: {},
            isUserDataLoaded: false,
            services: {
                userAPI: new UserAPI(),
                questionsAPI: new QuestionsAPI()
            },
            functions: {
                setRedirectTo: (redirectTo) => {
                    let {globals} = this.state;
                    globals.redirectTo = redirectTo;
                    this.setState({globals: globals});
                },
                resetRedirectTo: () => {
                    let {globals} = this.state;
                    delete globals.redirectTo;
                    this.setState({globals: globals});
                },
                setLanguage: (newLang) => {
                    this.setState({lang: newLang});
                },
                setLanguageCookie: (lang) => {
                    var now = new Date(),
                        // this will set the expiration to 10 years
                        exp = new Date(now.getFullYear() + 10, now.getMonth(), now.getDate());

                    const {cookies} = this.props;
                    var options = {
                        expires: exp,
                        sameSite: false,
                        path: '/'
                    };
                    cookies.set('lang', lang, options);
                },
                getLanguageCookie: () => {
                    const {cookies} = this.props;
                    return cookies.get('lang');
                },
                clearLanguageCookie: () => {
                    const {cookies} = this.props;

                    var options = {
                        sameSite: false,
                        path: '/'
                    };
                    cookies.remove('lang', options);
                },
                decideLanguage: (userProfileLanguage) => {
                    const values = queryString.parse(this.props.location.search);
                    var language = values.lang;

                    if(!window.questiaSpecificWebsiteConfig.MULTI_LANGUAGE) {
                        language = window.questiaSpecificWebsiteConfig.DEFAULT_LANGUAGE;
                        this.state.functions.clearLanguageCookie();
                        this.state.functions.setLanguageCookie(window.questiaSpecificWebsiteConfig.DEFAULT_LANGUAGE);
                    } else if (typeof userProfileLanguage !== "undefined") {
                        language = userProfileLanguage;
                        this.state.functions.clearLanguageCookie();
                        this.state.functions.setLanguageCookie(userProfileLanguage);
                    } else if(typeof language !== "undefined")  {
                        this.state.functions.clearLanguageCookie();
                        this.state.functions.setLanguageCookie(values.lang);
                    } else {
                        language = this.state.functions.getLanguageCookie();
                        if (typeof language === "undefined") {
                            language = window.questiaSpecificWebsiteConfig.DEFAULT_LANGUAGE;
                            this.state.functions.clearLanguageCookie();
                            this.state.functions.setLanguageCookie(window.questiaSpecificWebsiteConfig.DEFAULT_LANGUAGE);
                        }
                    }

                    return language;
                },
                setRecommendedByCookie: this.setRecommendedByCookie,
                getRecommendedByFromCookie: this.getRecommendedByFromCookie,
                clearRecommendedByCookie: this.clearRecommendedByCookie,
            }
        };
    }

    setRecommendedByCookie = (recommendedBy) => {
        const {cookies} = this.props;
        var now = new Date(),
            // this will set the expiration to 10 years
            exp = new Date(now.getFullYear() + 10, now.getMonth(), now.getDate());
        var options = {
            expires: exp,
            sameSite: false,
            path: '/'
        };
        cookies.set('recommendedBy', recommendedBy, options);
    };

    getRecommendedByFromCookie = () => {
        const {cookies} = this.props;
        return cookies.get('recommendedBy');
    };

    clearRecommendedByCookie = () => {
        const {cookies} = this.props;
        var options = {
            sameSite: false,
            path: '/'
        };
        cookies.remove('recommendedBy', options);
    };

    initRecommendedBy = () => {
        const values = queryString.parse(this.props.location.search);

        // Read recommendedBy from url query params, if query param is not defined read it from cookie.
        var recommendedBy;
        if (typeof values.recommendedBy !== "undefined") {
            this.clearRecommendedByCookie();
            this.setRecommendedByCookie(values.recommendedBy);
            recommendedBy = values.recommendedBy;
        } else {
            recommendedBy = this.getRecommendedByFromCookie();
        }

        return recommendedBy;
    };


    redirectToRestrictPage = () => {
        if(window.questiaSpecificWebsiteConfig.RESTRICT_ACCESS_TO_PANEL && window.location.pathname !== "/restrict") {
            this.props.history.push('/restrict');
        }
    };

    componentDidMount() {
        this.redirectToRestrictPage();

        axios.defaults.baseURL = '/rest/';

        let globals = this.props.cookies.get('globals' + window.questiaSpecificWebsiteConfig.COOKIE_LOCALE);
        let lang = this.state.functions.decideLanguage();

        // !!! "lang" will get initialized in handleInitContextGlobalData() if user cookie is defined
        // Set it here only if no user is logged in
        if(typeof globals === "undefined") {
            this.setState({lang: lang}, () => {
                this.props.i18n.changeLanguage(lang,(err, t) => {
                    // console.log(err);
                });
            });
        }
    }

    checkIfProfileIsComplete = (profile) => {
        if(typeof profile.user.user_lastname === "undefined" || profile.user.user_lastname === "") {
            return false;
        }

        if(typeof profile.user.user_surname === "undefined" || profile.user.user_surname === "") {
            return false;
        }

        if(typeof profile.user.user_birthdate === "undefined" || profile.user.user_birthdate === "") {
            return false;
        }

        if(typeof profile.user.user_sex === "undefined") {
            return false;
        }

        if(Array.isArray(profile.city)) {
            return false;
        }

        return true;
    };

    handleInitContextGlobalData = (withRedirect) => {
        const {cookies} = this.props;

        // Init globals from cookies and Adding instances of configured services
        let globals = cookies.get('globals' + window.questiaSpecificWebsiteConfig.COOKIE_LOCALE);
        axios.defaults.headers.common['Authorization'] = globals.authorization;
        axios.defaults.baseURL = '/rest/';
        globals.Axios = axios;
        globals.updateUserInfo = this.updateUserInfo;

        if(this.state.globals.redirectTo){
            globals.redirectTo = this.state.globals.redirectTo;
        }

        this.setState({globals: globals},
            () => {
                // Get user details and save them globally
                this.state.services.userAPI.getUserDetails(this.state.globals.userId,
                    (response) => {
                        if(response.data.user.user_email.includes(AuthenticationUtils.FINGERPRINT_DOMAIN)) {
                            window.location = '/login';
                        }

                        var globals = this.state.globals;
                        globals.hasUserCompletedProfile = this.checkIfProfileIsComplete(response.data);

                        var lang = this.state.functions.decideLanguage(response.data.language.lang_panel_directory_name);

                        this.props.i18n.changeLanguage(lang,(err, t) => {
                            // console.log(err);
                        });

                        this.state.functions.clearLanguageCookie();
                        this.state.functions.setLanguageCookie(lang);

                        this.setState({globals: globals, lang: lang, userInfo: response.data, isUserDataLoaded: true}, () => {
                            if(withRedirect) {
                                let path = this.state.globals.redirectTo ? this.state.globals.redirectTo : '/app/';
                                if(this.state.globals.redirectTo) this.state.functions.resetRedirectTo();
                                this.props.history.push(path);
                            }
                        });
                    },
                    (error) => {
                        window.location = '/login';
                    }
                );
            }
        );
    };

    updateUserInfo = ()=>{
        this.state.services.userAPI.getUserDetails(this.state.globals.userId,
            (response) => {
                let globals = this.state.globals;
                globals.hasUserCompletedProfile = this.checkIfProfileIsComplete(response.data);
                this.setState({globals: globals, userInfo: response.data, isUserDataLoaded: true});
            },
            (response) => {
                console.log(response)
            }
        );
    };

    handleResetContextGlobalData = () => {
        this.setState({
            isUserDataLoaded: false,
            globals: {}
        });
        delete axios.defaults.headers.common['Authorization'];
    };

    handleCloseMessageBox = (e) => {
        this.setState({showPrivacyMessage: false})
    };

    render() {

        const showPrivacyMessageBox = (window.questiaSpecificWebsiteConfig.SHOW_PRIVACY_DIALOG && this.state.showPrivacyMessage && !this.state.globals.userId);

        return (
            <div>
                <link rel="stylesheet" type="text/css" href={"./specific/css/" +"main.css"}/>
                <link rel="stylesheet" type="text/css" href={"./specific/css/" +"questia-ui.css"}/>
                {showPrivacyMessageBox &&
                <PrivacyMessageBox handleCloseMessageBox={this.handleCloseMessageBox}/>
                }
                <GlobalContext.Provider
                    value={{
                        globals: this.state.globals,
                        lang: this.state.lang,
                        recommendedBy: this.state.recommendedBy,
                        userInfo: this.state.userInfo,
                        services: this.state.services,
                        functions: this.state.functions,
                        isUserDataLoaded: this.state.isUserDataLoaded,
                        handleInitContextGlobalData: this.handleInitContextGlobalData,
                        handleResetContextGlobalData: this.handleResetContextGlobalData
                    }}>
                    {this.props.children}
                </GlobalContext.Provider>
            </div>
        );
    }
}

export default withCookies(withRouter(withTranslation()(GlobalProvider)));