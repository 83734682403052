import {Component} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { withTranslation } from 'react-i18next';

class ProposeQuestionColumnFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filterValue: ""
        };
    }

    onChangeText = (event) => {
        const filterValue = event.target.value;
        const newState = {
            ...this.state,
            filterValue
        };
        this.setState(newState);
        this.props.onChange(newState);
    };

    render() {
        return (
            <div className="wrapper--search">
                <FontAwesomeIcon className="icon-font-awesome"  icon={faSearch}/>
                <input type="search" placeholder={this.props.t('propose.content.filter_placeholder', "Search ")} value={this.state.filterValue} onChange={this.onChangeText}/>
            </div>
        );
    }
}

export default withTranslation()(ProposeQuestionColumnFilter);