import React, {Component} from 'react';
import PropTypes from "prop-types";

export default class QuestionClassicChoice extends Component {
    static propTypes = {
        choice: PropTypes.object.isRequired,
        choiceType: PropTypes.object.isRequired,
        checked: PropTypes.bool.isRequired,
        handleAnswerSelection: PropTypes.func.isRequired,
        usesChoiceImages: PropTypes.bool.isRequired
    };

    constructor(props) {
        super(props);
    }

    render() {
        const {choice, choiceType, cardId} = this.props;
        const {usesChoiceImages} = this.props;
        const imageLink = usesChoiceImages ? (<img src={"/rest/widget/choice/" + choice.id + "/image"}/>) : "";

        switch (choiceType) {
            case "radio":
                return (
                    <div className="questia-question__radios">
                        <input
                            type="radio"
                            id={cardId + "_" + choice.value}
                            value={choice.value}
                            onClick={this.props.handleAnswerSelection}
                            checked={this.props.checked} />
                        <label htmlFor={cardId + "_" + choice.value}>
                            {choice.label} {choice.haveMedia !== "0" && imageLink}
                        </label>
                    </div>
                );
            case "checkbox":
                return (
                    <div className="questia-question__checkboxes">
                        <input
                            type="checkbox"
                            id={cardId + "_" + choice.value}
                            value={choice.value}
                            onClick={this.props.handleAnswerSelection}
                            checked={this.props.checked}/>
                        <label htmlFor={cardId + "_" + choice.value}>{choice.label}</label>
                        {choice.haveMedia !== "0" && imageLink}
                    </div>
                );
            default:
                return (
                    <h2> Choice type is not found! </h2>
                );
        }
    }


}